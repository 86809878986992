import FreemiumBannerMobileComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerMobile/viewer/FreemiumBannerMobile';
import FreemiumBannerMobileController from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerMobile/viewer/FreemiumBannerMobile.controller';


const FreemiumBannerMobile = {
  component: FreemiumBannerMobileComponent,
  controller: FreemiumBannerMobileController
};


export const components = {
  ['FreemiumBannerMobile']: FreemiumBannerMobile
};


// temporary export
export const version = "1.0.0"
